import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useAuthContext } from 'src/auth/hooks';
// routes
import { RouterLink } from 'src/routes/components';
import MainLogo from 'src/assets/logo/Dash-Bean-Logo.png'
import { getLogo } from 'src/auth/context/firebase/auth-provider';
import Cookies from 'js-cookie';



// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const [businessLogo, setBusinessLogo] = useState()
  const { user } = useAuthContext();

  const saveDataToCookies = (logoData) => {
    const data = logoData
    Cookies.set('logo', JSON.stringify(data));
  };

  const retrieveDataFromCookies = () => {
    const data = Cookies.get('logo');
    if (data) {
      const parsedData = JSON.parse(data);
      setBusinessLogo(parsedData.url);
    } else if (!data) {
      getBusinessLogo(); // Call the function to retrieve logo from Firebas
    }
  };



  useEffect(() => {
    retrieveDataFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessLogo])

  const getBusinessLogo = async () => {
    const logo = await getLogo(user?.uid);
    console.log(logo, "LOGO")
    if (logo) {
      setBusinessLogo(logo.url);
      saveDataToCookies(logo)
    } else if (!logo) {
      setBusinessLogo(MainLogo)
    }
  }

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src={MainLogo}
      sx={{ maxWidth: 50, maxHeight: 50, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
